<template>
  <v-sheet
    color="grey lighten-4"
    class="pa-2"
  >
    <v-row>
      <v-col
        class="text-left"
      >
        <v-btn
          small
          text
          outlined
          color="success"
          @click="openDialogItem()"
        >
          <v-icon
            v-text="'mdi-plus'"
            left
          />
          Adicionar Item
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Ordem</th>
                <th class="text-left">Título</th>
                <th class="text-left">Justificar</th>
                <th class="text-left">Próxima</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="items.length <=0 "
              >
                <td
                  colspan="5"
                  class="title"
                >
                  <i>Nenhum item encontrado</i>
                </td>
              </tr>
              <tr
                v-for="(item, index) in items"
                :key="index"
              >
                <td class="text-left">{{ item.order }}</td>
                <td class="text-left">{{ item.title }}</td>
                <td class="text-left">{{ item.explain ? 'Sim' : 'Não' }}</td>
                <td class="text-left">
                  <v-tooltip
                    v-if="item.nextQuestion"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-text="'mdi-arrow-decision-auto'"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ item.nextQuestion.title }}</span>
                  </v-tooltip>
                </td>
                <td class="text-right">
                  <v-btn
                    icon
                    small
                    @click="openDialogItem(index)"
                  >
                    <v-icon
                      v-text="'mdi-pencil'"
                      small
                    />
                  </v-btn>
                  <v-btn
                    icon
                    small
                    @click="removeItem(item)"
                  >
                    <v-icon
                      v-text="'mdi-delete'"
                      small
                    />
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    

    <dialog-question-items-fields
      v-model="showDialogItem"
      :item="editItem"
      :max-order="maxOrder"
      :survey-id="surveyId"
      @insert="addItem"
      @update="updateItem"
    />
  </v-sheet>
</template>

<script>

  export default {

    components: {
      DialogQuestionItemsFields: () => import('@/components/dialog/questions/DialogQuestionItemsFields'),
    },

    props: {
      value: {
        type: Array,
        default: [],
      },
      surveyId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        showDialogItem: false,
        editItem: {},
        editKey: -1,
      }
    },

    computed: {
      items: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      maxOrder () {
        if (this.items.length <= 0) {
          return 0
        }

        return Math.max.apply(Math, this.items.map(function(o) { return o.order }))
      }
    },

    methods: {
      addItem (item) {
        this.items.push(item)
      },

      updateItem (item) {
        this.$set(this.items, this.editKey, item)

        this.editItem = null
        this.editKey = null
      },

      removeItem (item) {
        const index = this.items.indexOf(item)
        if (index !== -1) {
          this.items.splice(index, 1);
        }
      },

      openDialogItem (key = null) {
        if (key !== null) {
          this.editItem = this.items[key]
          this.editKey = key
        } else {
          this.editItem = null
          this.editKey = null
        }

        this.showDialogItem = true
      }
    }

  }
</script>
